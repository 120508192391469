import { CalendarIcon } from "svg";
import { format } from "date-fns";

import { getHelperText, getLabel, handleFieldData } from "../actions";
import { Field } from "components/styledComponents/StyledForms";
import { DatePicker } from "@mui/x-date-pickers";

const MIN_DATE = new Date("1900-01-01");
const MAX_DATE = new Date("2099-12-31");

export const mapDatePickerError = (reason, maxDate = MAX_DATE, minDate = MIN_DATE) => {
  switch (reason) {
    case "invalidDate":
      return { tag: "DATE.INVALID" };
    case "disablePast":
      return { tag: "DATE.MIN", data: { limit: format(Date.now(), "P") } };
    case "minDate":
      return { tag: "DATE.MIN", data: { limit: format(minDate, "P") } };
    case "maxDate":
      return { tag: "DATE.MAX", data: { limit: format(maxDate, "P") } };
    case "disableFuture":
      return { tag: "DATE.MAX", data: { limit: format(Date.now(), "P") } };
    default:
      return null;
  }
};

const popperProps = {
  placement: "bottom-start",
  modifiers: [
    { name: "flip", enabled: true },
    // {
    //   name: "preventOverflow",
    //   enabled: true,
    //   options: {
    //     rootBoundary: "viewport",
    //     padding: 8,
    //   },
    // },
    {
      name: "offset",
      options: {
        offset: [-44, 8],
      },
    },
  ],
};

const convertToUTCFormatString = (value) => {
  const dateValue = new Date(value);
  const utcDay = dateValue.getUTCDate();
  const utcMonth = dateValue.getUTCMonth() + 1;
  const utcYear = dateValue.getUTCFullYear();

  const convertedDate = `${utcMonth}/${utcDay}/${utcYear}`;

  return convertedDate;
};

// The purpose of useUTC is to force the mui DatePicker v5 to work with UTC+0 dates only, since the date of birth should not change between time zones.
export const MtcDatePicker = ({ pickerProps, useUTC, ...props }) => {
  const { id, name, helperText, value, hideLabel, handleChanges, marginDisabled, label, error, disabled, required } =
    handleFieldData(props);

  const onChange = (date) => {
    let value = date;
    if (useUTC) {
      const time = date.getTime();
      const offset = date.getTimezoneOffset();

      const newDate = new Date(time - offset * 60 * 1000);
      value = newDate.toISOString();
    }
    return handleChanges({ target: { value, name } }, true);
  };

  const renderInput = (renderInputProps) => {
    let customRenderInputProps = {};

    if (useUTC) {
      customRenderInputProps = {
        ...renderInputProps,
        inputProps: { ...renderInputProps.inputProps, value: value ? convertToUTCFormatString(value) : value },
      };
    }

    return (
      <Field
        {...(useUTC ? customRenderInputProps : renderInputProps)}
        fullWidth
        margin={marginDisabled ? "none" : "normal"}
        helperText={getHelperText(error, name, helperText)}
        error={Boolean(error)}
        required={required}
        id={id}
      />
    );
  };

  return (
    <DatePicker
      disabled={disabled}
      value={(value && (useUTC ? convertToUTCFormatString(value) : value)) || null}
      label={getLabel(name, label, hideLabel)}
      onChange={onChange}
      views={["year", "month", "day"]}
      PopperProps={popperProps}
      allowSameDateSelection
      PaperProps={{ elevation: 2 }}
      components={{ OpenPickerIcon: CalendarIcon }}
      OpenPickerButtonProps={{ sx: { svg: { fontSize: 20 } } }}
      InputAdornmentProps={{ position: "start" }}
      renderInput={renderInput}
      {...pickerProps}
      defaultCalendarMonth={pickerProps?.minDate || new Date()}
      minDate={pickerProps?.minDate || MIN_DATE}
      maxDate={pickerProps?.maxDate || MAX_DATE}
    />
  );
};
